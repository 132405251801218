.cardImage {
  height: 20vh;
  width: 20vw;
  background-color: #555;
  /* margin-left: 100px;
  margin-bottom: 30px; */
}

.cardImageWrapper {
  display: flex;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 40vh;
  width: 34vw;
  margin-left: 30px;
  margin-bottom: 30px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

.label {
  color: #acaeb1;
  font-weight: 600;
  font-size: larger;
  padding-left: 30px;
  text-align: left;
  min-width: 100px;
  margin-top: 40px;
}

.sublabel {
  display: flex;
  margin-left: 30px;
  margin-top: 10px;
  padding: 10px 50px;
  background-color: #1e2324;
  width: 100%;
  color: white;
  font-size: large;
  margin-top: 10px;
  border-radius: 10px;
  left: 0;
  right: 0;
}

.hoverCard {
  background-color: #373f41;
  color: white;
}

@keyframes animateDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(3px);
  }
}

.fix-new {
  font-size: 10px !important; /*we can easly change credit card size*/
}

.container {
  flex-direction: unset;
  display: flex;
}

@media screen and (max-width: 840px) {
  .container {
    flex-direction: column-reverse;
  }
  .form-box {
    margin-right: 0rem;
    margin-top: 1rem;
  }
}

.card-new {
  /* background: url(./any_pattern.jpg) !important; */
  height: 20%;
  border: none !important;
  /* background: linear-gradient(to right top, #280537, #56034c, #890058, #bc005b, #eb1254) !important; */
  background: linear-gradient(200deg, #1382be, #0c548f) !important;
  margin-left: 0px;
  max-width: 100%;
}

.form-box {
  background-color: #1e2324;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-right: 1rem;
  padding: 2%;
  padding-top: 0%;
}

.input-text {
  border: 1px solid #dddddd;
  background: #f8f8f8;
  padding: 0 1rem;
  line-height: 2.5rem;
  font-size: 1.1rem;
  border-radius: 0.1rem;
  width: 100%;
  margin: 1rem 0;
  color: #666666;
  max-width: 92%;
}

.semi {
  width: 43%;
}

.flex-wrapper {
  display: flex;
}

.flex-wrapper div {
  margin-right: 2rem;
}

.error {
  background-color: rgb(247, 144, 144);
}

.cancelButton {
  color: grey;
  margin-left: 10%;
}

.cancelButton:hover {
  color: red;
  cursor: pointer;
}

.cancelButton1 {
  color: grey;
}

.cancelButton1:hover {
  color: red;
  cursor: pointer;
}
