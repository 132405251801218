.rightGridQA {
  background-color: #233d53;
  font-size: 30%;
  z-index: 1;
  padding: 2%;
  padding-top: 15px;
  height: 100vh;
  width: 100%;
  min-width: 200px;
  left: 75%;
  position: fixed;
  padding-left: auto;
}

.newSchedulemaps {
  height: 100%;
  width: 90% !important;
  margin-left: 20px !important;
  margin-top: 20px;
  border-radius: 7px;
  box-shadow: -14px 12px 4px rgb(0, 0, 0, 0.3);
  /* aspect-ratio: 16/9; */
}

.detailsInput {
  width: 100%;
  margin-left: 3%;
}

.EnterDetailsInput {
  border: 2px solid #eff2f7 !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-left: 5%;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  max-width: 300px;
}
.EnterDetailsInputStripe {
  border: 2px solid black !important;
  background-color: white;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-left: 0%;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  font-family: "Poppins";
  max-width: 600px;
}

.loginform {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  margin-top: 1%;
  margin-left: 1%;
  margin-top: 1%;
  max-width: 100%;
  width: 100%;
}

.paymentElement {
  max-width: 100%;
  width: 100%;
  display: flex;
}
.EnterDetailsInputStripe {
  border: 2px solid black !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-left: 0%;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  /* max-width: 600px; */
}
@media screen and (max-width: 600px) {
  .EnterDetailsInput {
    border: 2px solid #eff2f7 !important;
    border-radius: 5px;
    padding: 8px 10px;
    margin-top: 2px;
    margin-left: 3%;
    width: 100%;
    margin-bottom: 10px;
    white-space: nowrap;
    max-width: 300px;
  }

  .rightGridQA {
    width: 100%;
    height: 100%;
    width: 100%;
    min-width: 100vw;
    padding-left: 3%;
    /* margin-left: 9%; */
    position: relative;
    left: 1%;
  }
  .paymentElement {
    margin-left: 3%;
    width: 80%;
  }
}

@media screen and (max-width: 970px) {
  .paymentElement {
    margin-left: 3%;
    width: 80%;
  }
}

@media screen and (max-width: 955px) and (min-width: 600px) {
  .rightGridQA {
    background-color: #233d53;
    font-size: 30%;
    z-index: 1;
    height: 100vh;
    width: 280px;
    left: 69%;
    position: fixed;
    padding-left: auto;
  }
  .paymentElement {
    margin-left: 3%;
    margin-right: auto;
    width: 80%;
  }
}
