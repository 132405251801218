@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

.login {
  /* padding: 25px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: "white";
}

.login_info {
  margin-top: 50px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: "white";
  /* gap: 40px;
  min-width: 300px; */
}

.login_form {
  display: flex;
  text-align: left;
  flex-direction: column;
  text-align: left;
  align-items: center;
}

.login_form > label {
  color: #696b6e;
  font-weight: 600;
  font-size: 15px;
  padding-left: 20px;
  text-align: left;
  border-radius: 50px;
  min-width: 100px;
}

.labelText {
  color: #696b6e;
  font-weight: 600;
  font-size: 15px;
  padding-left: 33px;
  text-align: left;
  border-radius: 50px;
  min-width: 100px;
}

.login_form > input {
  margin: 10px;
  margin-bottom: 30px;
  margin-left: 15px;
  outline: none;
  border-radius: 10px;
  max-height: 40px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: #373a47;
  width: 50%;
  max-width: 800px;
  font-weight: 600;
  min-width: 200px;
}

.loginInput {
  outline: none;
  border-radius: 28px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: #373a47;
  max-width: 800px;
  font-weight: 600;
  min-width: 200px;
}

.buttonPrimary {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: #3457b1;
}

.login_divider {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.login_divider > span {
  font-weight: 600;
  color: #5c5e62;
}

.alternateLogin {
  color: #b6c4d9;
  font-weight: 500;
  width: 100%;
  font-size: 18px;
}

.alternateLogin > a,
a:hover {
  color: #b6c4d9;
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
  background-color: transparent;
}

.navLink,
.navLink:hover {
  background-color: transparent;
}

* {
  box-sizing: border-box;
}

body {
  background-color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 20px 0 0;
  height: 10px;
  width: 300px;
}

.progress-done {
  background: linear-gradient(to left, #5a52ff, #7bd9f6);
  box-shadow: 0 3px 3px -5px #5a52ff, 0 2px 5px #7bd9f6;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

@keyframes example {
  0% {
    right: 100px;
  }
  100% {
    left: 100;
    right: 0px;
  }
}

/* The element to apply the animation to */
.animate {
  position: relative;
  animation-name: example;
  animation-duration: 2s;
}

.custom-file-input {
  outline: none;
  border-radius: 10px;
  background-color: #1b1f20;
  color: #71768d;
  font-weight: 600;
  min-width: 200px;
  text-align: center;
  max-width: 600px;

  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6px 12px;
  cursor: pointer;
}

.footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: #3457b1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
  align-items: center;
}

.stepper {
  background-color: #1b1f20 !important;
  color: blanchedalmond;
}

.MuiTypography-root .MuiStepLabel-label .MuiTypography-body2 {
  color: white !important;
}

.signUpInput {
  border: 2px solid #eff2f7 !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-bottom: 10px;
}

.toggleButton1 {
  background-color: grey !important;
  margin-right: 5vw !important;
  border-radius: 5px;
  width: 100%;
  border: none;
  color: white !important;
  min-width: 100px;
  font-size: 100% !important;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}
.toggleButton1:active {
  background-color: #3457b1 !important;
}

.toggleButton1:hover {
  background-color: #3457b1 !important;
}
