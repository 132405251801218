.h1Secondary {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1b1c57;
  font-size: 48px;
  text-align: left;
  color: white;
  font-weight: 600;
  line-height: 60px;
  text-transform: capitalize;
  text-decoration: underline;
  -webkit-text-decoration-color: #187cae; /* Safari */
  text-decoration-color: #187cae;
  text-underline-offset: -2%;
}

.mobilewhoarewe {
  display: none;
}

.heroSection {
  display: flex;
  width: 100%;
}

.heroPic {
  display: block;
  margin-top: 10vh;
}

.whoarewePic {
  display: block;
}

.mobileBG {
  display: none;
  margin-top: 15vh;
}

.rec.rec-arrow-left,
.rec.rec-arrow-right {
  background-color: #ffffff;
  box-shadow: 0 0 1px 3px #000000;
}

.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled {
  background-color: #187cae;
  box-shadow: 0 0 1px 3px #031f2e;
}

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #197cae;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #197cae;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Responsive for Full HD Screen(1080px) */
@media screen and (max-width: 1049px) {
  .heroPic {
    display: block;
    min-height: 800px;
  }
  .whoarewePic {
    max-width: 80%;
  }
  .heroSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .mobileBG {
    display: none;
  }
  .rec.rec-arrow {
    visibility: hidden;
    display: none;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
}

/* Responsive for HD Screen */
@media screen and (max-width: 955px) {
  .heroPic {
    display: block;
    margin-top: 0%;
  }
  .whoarewePic {
    max-width: 80%;
  }
  .heroSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mobileBG {
    display: none;
    width: 100%;
  }
  .rec.rec-arrow {
    visibility: hidden;
    display: none;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
}

@media screen and (max-width: 1700px) {
  .rec.rec-arrow {
    visibility: hidden;
    display: none;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
}

/* Responsive for mid device */
@media screen and (max-width: 689px) {
  .heroPic {
    display: block;
    margin-top: 0%;
  }
  .whoarewePic {
    max-width: 80%;
  }
  .heroSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mobileBG {
    display: block;
    width: 100%;
  }
  .rec.rec-arrow {
    visibility: hidden;
    display: none;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
}

/* Responsive for tab */
@media screen and (max-width: 949px) {
  .heroPic {
    display: block;
    margin-top: 0%;
  }
  .whoarewePic {
    display: none;
  }
  .mobilewhoarewe {
    display: block;
  }
  .mobileBG {
    display: block;
    width: 100%;
  }
  .rec.rec-arrow {
    visibility: hidden;
    display: none;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
}



/* Responsive for Mobile */
@media screen and (max-width: 480px) {
  .mobileBG {
    display: block;
    width: 100%;
  }
  .heroPic {
    margin-top: 16%;
    height: 30%;
    /* height: 40px; */
    max-height: 30px;
  }
  .whoarewePic {
    display: none;
  }
  .mobilewhoarewe {
    display: flex;
  }
  .heroSection {
    display: flex;
    flex-direction: column;
  }
  .h1Secondary {
    font-size: 28px;
    line-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .rec.rec-arrow {
    visibility: hidden;
    display: none;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
}

.heroCarousel {
  display: block;
  width: 100vw;
}

@media screen and (max-width: 480px) {
  .heroCarousel {
    width: 100vw;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .heroCarousel {
    width: 100vw;
    height: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 900px) {
  .heroCarousel {
    width: 100vw;
    height: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 1080px) {
  .heroCarousel {
    width: 100vw;
    height: 90%;
    object-fit: contain;
  }
}

@media screen and (min-width: 1080px) {
  .heroCarousel {
    width: 100vw;
    height: 90%;
    object-fit: contain;
  }
}
