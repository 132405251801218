@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "@fontsource/lexend";

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  margin: 0;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  color: #999999;
  cursor: not-allowed;
}

.appbarNavlink {
  text-decoration: none;
  font-weight: bolder;
  color: black;
  margin-right: 3vw;
  margin-top: 10px;
  cursor: pointer;
}

.Container {
  height: 100vh !important;
}

.centered {
  display: grid;
  justify-content: center;
  align-items: center;
}

.button {
  height: 70% !important;
  width: 30% !important;
}

/* Burger Menu CSS [NavBar] */

.bm-item {
  display: inline-block;
  padding: 15px 0;
  border-bottom: 1px solid rgba(194, 192, 192, 0.2);
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 22px;
  right: 2rem;
  top: 25px;
}

.bm-burger-bars {
  background: #d2e4f5;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #1a1d1e;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 15px 0;
  border-bottom: 1px solid rgba(194, 192, 192, 0.2);
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.popup {
  margin-top: 1000px;
}

.right {
  float: right;
  background-color: #23282b;
}

/* Maptool Style */

.mapTool {
  display: grid;
  background-color: #171719;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 15% 85%;
  grid-template-areas: "mapDrawerLeft Map";
}

.Map {
  grid-area: Map;
  padding: 15px;
  background-color: #171719;
  height: 100vh;
}

.map {
  height: 75%;
  width: 97%;
  margin-top: 20px;
  margin-left: 2%;
}

.mapHeader {
  color: white;
  padding-top: 20px;
  font: Roboto;
  font-weight: 400;
  font-size: 36;
  letter-spacing: 0.085em;
}

.saveToSheets {
  margin-top: 20px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 30px;
  border: none;
  color: white;
  font-weight: bold;
  letter-spacing: 0.085em;
  background-color: rgba(44, 118, 230, 0.514);
  width: 20%;
}

.saveToSheets > a {
  color: white;
  text-decoration: none;
}

.mapZoneButton {
  display: inline;
  place-items: left;
  margin-top: 20px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 30px;
  border: none;
  width: 10%;
}

.mapDrawerLeft {
  grid-area: mapDrawerLeft;
  align-items: left;
  background: linear-gradient(182.6deg, #146b99 -15.3%, #01132c 97.83%);
}

.mapDrawerRight {
  grid-area: mapDrawerLeft;
  background-color: #1b1f20;
  align-items: center;
  display: flex;
  position: relative;
  place-items: start normal;
  justify-content: center;
  float: left;
  padding-top: 10px;
  height: 100%;
  width: 50%;
  margin-left: 90px;
  box-shadow: 5px 0px 5px rgb(8, 8, 8);
}
.mapDrawerInfo {
  grid-area: mapDrawerRight;
  background-color: #1b1f20;
  align-items: center;
  display: block;
  position: relative;
  place-items: start normal;
  justify-content: center;
  padding-top: 10px;
  height: 100%;
  width: 100%;
  box-shadow: 5px 0px 5px rgb(8, 8, 8);
}

.mapTools {
  display: block;
  height: 8%;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.mapTools:hover {
  background-color: rgba(44, 118, 230, 0.514);
}

.mapTools:focus-within {
  background-color: rgba(44, 118, 230, 0.514);
}

.mapToolIcon {
  margin-top: 0;
  padding-top: 25%;
}
.tool {
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: white;
  padding: 10px;
}

.editTool {
  color: rgb(73, 70, 70);
  display: inline-block;
  font-size: 12px;
  padding-left: 130px;
  padding-bottom: 10px;
}

.editTool:hover {
  color: rgba(129, 120, 120, 0.993);
  font-size: 17px;
}

.video-responsive {
  justify-content: center;
  align-items: center;
  background-color: #1b1f20;
  color: white;
  width: 100vw;
  height: 100vh;
}

.video-responsive > video {
  width: 100vw;
  height: 80vh;
  padding-top: 5%;
  align-items: center;
  background-color: #1b1f20;
}

.video-responsive h1 {
  padding-top: 10vh;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  padding: 0px;
  font-size: 5vh;
  color: white;
}

.search {
  font-size: 16px;
  padding: 20px;
  margin-bottom: 5%;
  height: 10%;
  border-radius: 30px;
  width: 80%;
  align-items: center;
  text-align: left;
  color: #233d53;
  background-color: white;
  min-width: 80%;
  margin-right: 10px;
  min-height: 20px;
  padding-left: 2%;
  font-family: Poppins;
}

.buttonResponsive {
  background-color: #187cae;
  border: none;
  border-radius: 28px;
  margin: 0px;
  padding: 5px 10px;
  font-weight: 500;
  white-space: nowrap;
  width: 100%;
  min-width: 175px;
  height: 100px;
  text-align: center;
  align-items: center;
  max-height: 50px;
  margin-bottom: 5%;
  margin-left: 10%;
  color: white;
  font-family: "Poppins";
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .buttonResponsive {
    font-size: 14px;
    width: 100%;
    min-width: 130px;
  }
  .search {
    min-width: 55%;
    margin-left: 10%;
  }
  * {
    font-family: "Poppins";
    /* color: #393c41; */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .map {
    height: 50%;
    width: 100%;
    margin-top: 30px;
  }

  .mapTool {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 25% 25%;
    grid-template-areas:
      "Map"
      "mapDrawerLeft"
      "mapDrawerRight";
  }

  .mapTools {
    flex-direction: row;
    display: block;
    margin: 0%;
  }

  .tool {
    margin: 0;
    text-align: center;
    /* width: 100vw;
    width: 100vh; */
    cursor: pointer;
    color: white;
    padding: 10px;
  }

  .mapDrawerLeft {
    grid-area: mapDrawerLeft;
    background-color: #292c31;
    align-items: center;
    display: flex;
    position: relative;
    place-items: start normal;
    justify-content: center;
  }
}

.cardContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 30vw;
  height: 60%;
  display: flex;
  flex-direction: column;
}

.card > form {
  margin: 10px;
  height: 100vh;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timeslot {
  size: 12pc;
  display: inline;
  width: 100%;
  color: rgb(255, 255, 255);
  margin: 20px;
  justify-content: left;
  align-items: left;
  align-content: left;
  justify-items: left;
}

.pay {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 30px;
  max-height: 100px;
  border: none;
  padding: 12px 40px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.mapInput {
  /* margin-top: 20px; */
  outline: none;
  border-radius: 10px;
  max-height: 50px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: black;
  width: 30%;
  height: 20vh;
  max-width: 800px;
  font-weight: bold;
  min-width: 200px;
  padding-left: 10px;
}

.mapInput:active {
  border: none;
  outline: none;
}

.buttonPrimary {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary2 {
  background-color: #187cae;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
  float: "right";
  display: "flex";
  justify-content: "flex-end";
}

.buttonPrimary3 {
  background-color: #ffffff;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: 4px solid #187cae;
  padding: 4px 15px;
  border-radius: 69px;
  color: #187cae;
  min-width: 100px;
  font-size: 100%;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: #3457b1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.holderButton {
  animation-name: heartbeat;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px 10px #91b7bf;
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
    box-shadow: 0 0 0 0 #91b7bf;
  }
}
