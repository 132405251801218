@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.rightContactGrid {
  background-color: #233d53;
  font-size: 30%;
  z-index: 1;
  padding: 0.25%;
  padding-top: 10px;
  padding-left: 5px;
  height: 100vh;
  width: 100%;
  min-width: 100px;
  left: 73.6%;
  position: fixed;
  padding-left: auto;
}

.newSchedulemaps {
  height: 100%;
  width: 90% !important;
  margin-left: 20px !important;
  margin-top: 20px;
  border-radius: 7px;
  box-shadow: -14px 12px 4px rgb(0, 0, 0, 0.3);
  /* aspect-ratio: 16/9; */
}

.detailsInputContact {
  width: 100%;
}

.EnterDetailsInput {
  border: 2px solid #c4c7cb !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-left: 5%;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  max-width: 300px;
}

.EnterDetailsInputStripe {
  border: 2px solid black !important;
  background-color: white;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-left: 0%;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  font-family: "Poppins";
  max-width: 600px;
}

.loginform {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  margin-top: 1%;
  margin-left: 1%;
  margin-top: 1%;
  max-width: 100%;
  width: 100%;
}

.EnterDetailsInputStripe {
  border: 2px solid black !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-left: 0%;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  /* max-width: 600px; */
}

@media screen and (max-width: 600px) {
  .EnterDetailsInput{
   min-width: 89.5vw; 
  }
  .rightContactGrid {
    width: 100%;
    height: 100%;
    width: 100%;
    padding-top: 2%;
    min-width: 100vw;
    padding-left: 3%;
    /* margin-left: 9%; */
    position: relative;
    left: 1%;
  }
}

@media screen and (max-width: 955px) and (min-width: 600px) {

  .rightContactGrid {
    background-color: #233d53;
    font-size: 30%;
    z-index: 1;
    height: 100vh;
    width: 280px;
    left: 69%;
    position: fixed;
    padding-left: auto;
  }
}
