.heading4 {
  font-size: 30px;
  margin: 0px;
  padding-top: 30px;
  font-weight: bolder;
}

.Grid {
  background-color: #eef1f0;
  margin-top: 30px;
  margin-left: 10%;
  margin-bottom: 2%;
}

.Grid2 {
  width: 100%;
  background-color: #eef1f0;
  margin-top: 30px;
  margin-left: 4%;
  margin-bottom: 2%;
  max-height: 30vh;
}

#root {
  display: flex;
  align-items: center;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  border: none;
}

.paymentForm {
  padding-left: 20px;
  font-size: 14px !important;
}

.dateInput {
  outline: none;
  border: none;
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #3457b1;
  border-radius: 5px;
  max-height: 40px;
  width: 25%;
  max-width: 200px;
  min-width: 200px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.signUpInput {
  border: 2px solid #eff2f7 !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-bottom: 10px;
  /* width: 100%!important; */
}

.greyInput {
  border: none !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-bottom: 10px;
  background-color: #d1d2d2;
  /* width: 100%!important; */
}

#payment-element {
  margin-bottom: 24px;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #197cae;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #197cae;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
  .signUpInput {
    width: 100% !important;
    margin-top: 2% !important;
    margin-bottom: 4% !important;
  }
  .newSchedulemap {
    height: 100%;
    width: 90% !important;
    margin-left: 20px !important;
    margin-top: 20px;
    border-radius: 7px;
    box-shadow: -14px 12px 4px rgb(0, 0, 0, 0.3);
    /* aspect-ratio: 16/9; */
  }
  .gradientCard {
    width: 78vw !important;
    display: grid;
    place-items: center;
    border: none;
    border-radius: 10px;
  }
  .dateInput {
    /* width: 78%!important; */
    width: "90vw";
    min-width: 280px;
    margin-left: 50%;
  }
}

.gradientCard {
  width: 100%;
  display: grid;
  place-items: center;
  margin: 2%;
  padding: 8%;
  border: none;
  border-radius: 10px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -7px 9px 4px rgba(0, 0, 0, 0.25),
    -24px 13px 20px 5px rgba(0, 0, 0, 0.2); */
}

.newSchedulemap {
  height: 100%;
  width: 100%;
  margin-top: 20px;
  border-radius: 7px;
  box-shadow: -14px 12px 4px rgb(0, 0, 0, 0.3);
  aspect-ratio: 16/9;
}
